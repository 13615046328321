import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { URLs } from '../../../core/conf/urls';
import { FertilizerProductOption } from '../model/fertilizer-product-option.model';

@Injectable({ providedIn: 'root' })
export class FertilizerProductService {

  blendProducts: any[];
  appliedProducts: any;
  blendProductsValue: any;
  appliedProductsValue: any;

  // tslint:disable-next-line: variable-name
  private _fertilizerProductOptions = undefined;
  private subject = new BehaviorSubject(this._fertilizerProductOptions);
  // tslint:disable-next-line: variable-name
  private _fertilizerProductOptions$: Observable<any> = this.subject.asObservable();

  get fertilizerProductOptions(): any {
    return this._fertilizerProductOptions;
  }

  set fertilizerProductOptions(value: any) {
    this._fertilizerProductOptions = value;
    this.subject.next(this._fertilizerProductOptions);
  }

  get fertilizerProductOptions$(): Observable<any> {
    return this._fertilizerProductOptions$;
  }

  constructor(private httpClient: HttpClient) { }

  all(): Observable<FertilizerProductOption[]> {
    const params = new HttpParams().set('include-custom', 'true');
    return this.httpClient.get<FertilizerProductOption[]>(URLs.FERTILIZER_PRODUCT_OPTION_ENDPOINT, { params });
  }
}
